<template>
    <div>
        <ts-page-title :title="$t('jobTitle.pageTitle')" :breadcrumb="[
            { text: $t('home'), href: '/' },
            {
                text: $t('jobTitle.pageTitle'),
                active: true
            }
        ]" />
        <ts-panel>
            <ts-panel-wrapper class="tw-flex tw-items-center tw-justify-between">
                <Button type="info" ghost @click="addNew">{{
                    $t("addNew")
                }}</Button>
                <div class="tw-justify-end tw-space-x-2">
                    <Input search v-model="search" :placeholder="$t('jobTitle.search')" :style="width"
                        @on-focus="() => (width = 'width: 500px')" @on-blur="() => (width = 'width: 300px')" />
                </div>
            </ts-panel-wrapper>
            <Table :columns="columns" :data="resources" size="small" :loading="loading" max-height="700" stripe>
                <template slot-scope="{ row }" slot="action">
                    <a href="#" class="text-primary ml-2" @click.prevent="onEdit(row)" v-tooltip="$t('edit')">
                        <Icon type="ios-create" size="20" />
                    </a>
                    <Poptip confirm :title="$t('areYouSure')" @on-ok="onDelete(row)" :transfer="true" :ok-text="$t('yes')"
                        :cancel-text="$t('no')">
                        <a class="ml-2 text-danger" v-tooltip="$t('delete')" :disabled="row._deleting">
                            <i class="fas fa-circle-notch fa-spin" v-if="row._deleting"></i>
                            <Icon type="ios-trash" size="20" v-else />
                        </a>
                    </Poptip>
                </template>
            </Table>
            <div class="tw-flex tw-items-center tw-justify-end tw-p-4">
                <ts-i-pagination v-model="pagination" @navigate="fetchData"></ts-i-pagination>
            </div>

            <!-- form-action -->
            <Modal v-model="showForm" draggable sticky scrollable :mask="false" :footer-hide="true" :z-index="1022"
                :title="$t('jobTitle.pageTitle')">
                <from-action ref="form_action" @cancel="clearEdit" @fetchData="fetchData" />
            </Modal>
        </ts-panel>
    </div>
</template>

<script>
import { debounce } from "lodash";
import { mapState } from "vuex";
import FromAction from "./form";

export default {
    name: "job-titleIndex",
    components: {
        FromAction
    },
    data() {
        return {
            loading: false,
            showForm: false,
            width: "width: 300px"
        };
    },
    computed: {
        ...mapState("humanResource/jobTitle", ["resources", "pagination"]),
        search: {
            get() {
                return this.$store.state.humanResource.jobTitle.search;
            },
            set(newValue) {
                this.$store.commit(
                    "humanResource/jobTitle/SET_SEARCH",
                    newValue
                );
                this.$store.commit("humanResource/jobTitle/RESET_CURRENT_PAGE");
            }
        },
        columns() {
            return [
                {
                    title: this.$t("jobTitle.jobTitleKhTitle"),
                    key: "job_title_kh"
                },
                {
                    title: this.$t("jobTitle.jobTitleEnTitle"),
                    key: "job_title_en"
                },
                {
                    title: this.$t("jobTitle.role"),
                    key: "name"
                },
                {
                    title: this.$t("actions"),
                    key: "action",
                    slot: "action",
                    align: "center",
                    className: "tw-space-x-2",
                    width: 90
                }
            ];
        }
    },
    methods: {
        fetchData(attributes) {
            this.loading = true;
            this.$store
                .dispatch("humanResource/jobTitle/fetch", {
                    ...attributes
                })
                .then(() => {
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    this.notice({ type: "error", text: error.message });
                });
        },
        onEdit(record) {
            this.$store.commit("humanResource/jobTitle/SET_EDIT_DATA", record);
            this.showForm = true;
            this.$refs.form_action.setEditData();
        },
        clearEdit() {
            this.showForm = false;
            this.$refs.form_action.setEditData();
            this.$refs.form_action.clearInput();
            this.$store.commit("humanResource/jobTitle/SET_EDIT_DATA", {});
        },
        addNew() {
            this.$store.commit("humanResource/jobTitle/SET_EDIT_DATA", {});
            this.showForm = true;
            this.$refs.form_action.setEditData();
            this.$refs.form_action.clearInput();
        },

        async onDelete(record) {
            record._deleting = true;
            this.$store
                .dispatch("humanResource/jobTitle/destroy", record.job_title_id)
                .then(response => {
                    this.fetchData();
                    record._deleting = false;
                    this.notice({
                        type: response.message_id == 0 ? "success" : "warning",
                        text: response.message
                    });
                })
                .catch(error => {
                    this.notice({ type: "error", text: error.message });
                    record._deleting = false;
                });
        },

        notice(not) {
            this.$Notice[not.type]({
                title: "JOB TITLE",
                desc: not.text
            });
        }
    },
    watch: {
        search: debounce(function (search) {
            this.fetchData({ search });
        }, 500)
    },
    beforeRouteLeave(to, from, next) {
        this.$store.commit("humanResource/jobTitle/RESET_STATE");
        next();
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.fetchData();
        });
    }
};
</script>
